import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {button, language, menu_title, label, title, placeholder, price, ordList, orderTable, links} from '../helper';
import {Helper} from "../user_helper";
declare var $:any;
declare var google;
import { UUID } from 'angular2-uuid';
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { HeaderComponent } from '../header/header.component';
import {Location} from '@angular/common';
import * as moment from 'moment-timezone';
import {Response, Http} from '@angular/http';
import { map } from "rxjs/operators";

export interface AddressDetail{
	delivery_location: any[],
	delivery_address : string,
	delivery_user_phone : string,
	delivery_user_name : string,
	delivery_note : string,
	delivery_location1: any[],
	delivery_address1 : string,
	delivery_user_phone1 : string,
	delivery_user_name1 : string,
	delivery_note1 : string
}

export interface OrderPayment {
    _id: Object
    promo_payment: number,
    service_tax : number,
    total_admin_tax_price: number,
    total_cart_price: number,
    total_service_price: number,
    total_store_tax_price: number,
    total_item_count: number,
    total_item_price: number,
    item_tax: number,
    total_order_price: number,
    total: number,
    is_distance_unit_mile: Boolean,
    price_per_unit_time: number,
    price_per_unit_distance: number,
    delivery_price: number,
    is_promo_for_delivery_service: boolean
}

@Component({
  selector: 'app-courier-order',
  templateUrl: './courier-order.component.html',
  styleUrls: ['./courier-order.component.css'],
  providers: [Helper, HeaderComponent]
})
export class CourierOrderComponent implements OnInit {

	// private delivery_location : any[];
 //    private delivery_address : string = '';
 //    delivery_user_phone: string = '';
 //    delivery_user_name: string = '';
 //    delivery_note: string = '';
 //    private delivery_location1 : any[];
 //    private delivery_address1 : string = '';
 //    delivery_user_phone1: string = '';
 //    delivery_user_name1: string = '';
 //    delivery_note1: string = '';

    public delivery_currency : string = '';
    private cart_unique_token: string = '';
    private user_id: string = '';
    private server_token: string = '';
    public user_profile: string = '';
    wallet: number = 0;
    wallet_currency_code: string = '';
    is_use_wallet: boolean = true;
    edit_address: boolean = true;
  	public placeholder:any = placeholder;
    public button:any = button;
  	public language:any = language;
  	public menu_title:any = menu_title;
  	public label:any = label;
  	public title:any = title;
  	vehicles: any[] = [];
  	google_distance: number = 0;
  	google_time: number = 0;
  	selected_vehicle_id: string = '';
  	step: number = 1;
    form_data = new FormData();

  	city_id: string = '';
    private payment_gateway : any[] = [];
    private selected_payment_gateway: string = '';
    private card_list : any[] = [];
    private is_cash_payment_mode: Boolean = false;
    public order_payment : OrderPayment;

    new_image_array: any[] = [];

    public address_detail: AddressDetail = {
    	delivery_location: [],
		delivery_address : '',
		delivery_user_phone : '',
		delivery_user_name : '',
		delivery_note : '',
		delivery_location1: [],
		delivery_address1 : '',
		delivery_user_phone1 : '',
		delivery_user_name1 : '',
		delivery_note1 : ''
    }

  	constructor( public bln: BooleanService, public location: Location, public user_helper: Helper, private modalService: NgbModal, public HeaderComponent: HeaderComponent) { }


  	ngOnInit() {
  		this.bln.showLogin = false;
		  this.bln.showhedmid = true;
		  this.bln.showcart = false;
		  this.bln.isSticky = false;
		  this.bln.showLink = false;
		  this.bln.isHome = true;
		  this.bln.cart = true;
		  this.bln.showSignup = true;
		  this.bln.isAdd_product = false;
		  this.bln.isShop = true;
		  this.bln.isInner_page = true;
		  this.bln.isBod_btm_h = true;
      	this.bln.address = true;

      	this.order_payment = {
          _id: null,
          promo_payment: 0,
          total_cart_price: 0,
          service_tax : 0,
          total_admin_tax_price: 0,
          total_service_price: 0,
          total_store_tax_price: 0,
          total_item_count: 0,
          total_item_price: 0,
          item_tax: 0,
          total_order_price: 0,
          total: 0,
          is_distance_unit_mile: false,
          price_per_unit_distance: 0,
          price_per_unit_time: 0,
          delivery_price: 0,
          is_promo_for_delivery_service: false
      	};

      	if(!this.user_helper.router_id.user.city_id){
      		this.user_helper.router.navigate(['']);
      	}

      	this.address_detail.delivery_note = this.user_helper.user_cart.delivery_note;
      	this.cart_unique_token = localStorage.getItem('cart_unique_token');
      	let user = JSON.parse(localStorage.getItem('user'));
      	if(user && user._id){
          this.user_id = user._id;
          this.is_use_wallet = user.is_use_wallet
          this.server_token = user.server_token;
          this.user_profile = user.image_url;
          this.address_detail.delivery_user_name = user.first_name + user.last_name;
          this.address_detail.delivery_user_phone = user.phone;
      	}
      	var current_location: any = localStorage.getItem('current_location');
      	current_location = JSON.parse(current_location);
      	this.address_detail.delivery_location = [current_location.latitude, current_location.longitude];
        this.address_detail.delivery_address = current_location.address;
          

      	let autocompleteElm = <HTMLInputElement>document.getElementById('basket_address');
          let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {});

          autocomplete.addListener('place_changed', () => {
              this.user_helper.myLoading = true;
              var place = autocomplete.getPlace();
              this.address_detail.delivery_location = [place.geometry.location.lat(), place.geometry.location.lng()];
              this.address_detail.delivery_address = place.formatted_address;
              this.update_address();
          });

        let autocompleteElm1 = <HTMLInputElement>document.getElementById('basket_address1');
          let autocomplete1 = new google.maps.places.Autocomplete((autocompleteElm1), {});

          autocomplete1.addListener('place_changed', () => {
              this.user_helper.myLoading = true;
              var place = autocomplete1.getPlace();
              this.address_detail.delivery_location1 = [place.geometry.location.lat(), place.geometry.location.lng()];
              this.address_detail.delivery_address1 = place.formatted_address;
          });
      
  	}

    image_update($event) {
        const files = $event.target.files || $event.srcElement.files;

        for(var i=0; i<files.length; i++){
            const image_url = files[i];
            var reader = new FileReader();
            reader.onload = (e: any) => {
              this.new_image_array.unshift({base64_image: e.target.result, file: image_url});
            }
            reader.readAsDataURL(image_url);
        }
    }

    remove_new_image(index) {
        this.new_image_array.splice(index, 1)
    }

  	update_address() {
        // this.edit_address = false;

        let json = {
            latitude: this.address_detail.delivery_location[0],
            longitude: this.address_detail.delivery_location[1],
            city_id: this.user_helper.router_id.user.city_id
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CHECK_DELIVERY_AVAILABLE, json, (res_data) => {
            if(res_data.success){
      			var current_location: any = localStorage.getItem('current_location');
      			current_location = JSON.parse(current_location);

            	current_location.latitude = this.address_detail.delivery_location[0];
            	current_location.longitude = this.address_detail.delivery_location[1];
            	current_location.address = this.address_detail.delivery_address;
            	localStorage.setItem('current_location', JSON.stringify(current_location));
            } else {
      			var current_location: any = localStorage.getItem('current_location');
      			current_location = JSON.parse(current_location);

                this.address_detail.delivery_location = [current_location.latitude, current_location.longitude];
        		this.address_detail.delivery_address = current_location.address;
            }
        });
    }

    getVehicleList(data, content){
    	var json = {
    		delivery_type: this.user_helper.DELIVERY_TYPE.COURIER,
    		city_id: this.user_helper.router_id.user.city_id
    	}
    	this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_VEHICLES_LIST, json, (res_data) => {
        	
        	this.vehicles = res_data.admin_vehicles;

        	this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title' , windowClass:'product_pop with_img'}).result.then((result) => {
	            
	        }, (reason) => {
	            
	        });
        });
    }

    addtoCart(vehicle_id){
    	this.selected_vehicle_id = vehicle_id;
    	this.user_helper.user_cart.cart_data.cart = [];
    	this.user_helper.user_cart.total_cart_amount = 0;
    	this.user_helper.user_cart.total_item_tax = 0;
        this.user_helper.user_cart.cart_data.destination_addresses = [];               
    	this.user_helper.user_cart.destination_address.location = this.address_detail.delivery_location1;
    	this.user_helper.user_cart.destination_address.address = this.address_detail.delivery_address1;
    	this.user_helper.user_cart.destination_address.note = this.address_detail.delivery_note1;
    	var current_location: any = localStorage.getItem('current_location');
      	current_location = JSON.parse(current_location);
       	this.user_helper.user_cart.destination_address.city = current_location.city1;
        let user = JSON.parse(localStorage.getItem('user'));
        this.user_helper.user_cart.destination_address.user_details = {
            "name": this.address_detail.delivery_user_name1,
            "country_phone_code": user.country_phone_code,
            "phone": this.address_detail.delivery_user_phone1,
            "email": ''
        }
        this.user_helper.user_cart.destination_address.user_type = user.user_type;
        this.user_helper.user_cart.cart_data.destination_addresses.push(this.user_helper.user_cart.destination_address);


        this.user_helper.user_cart.cart_data.pickup_addresses = [];               
    	this.user_helper.user_cart.pickup_address.location = this.address_detail.delivery_location;
        this.user_helper.user_cart.pickup_address.address = this.address_detail.delivery_address;
       	this.user_helper.user_cart.destination_address.city = current_location.city1;
    	this.user_helper.user_cart.pickup_address.note = this.address_detail.delivery_note;
        this.user_helper.user_cart.pickup_address.user_type = user.user_type;
        this.user_helper.user_cart.pickup_address.user_details = {
            "name": user.first_name + ' ' + user.last_name,
            "country_phone_code": user.country_phone_code,
            "phone": user.phone,
            "email": user.email
        }
        this.user_helper.user_cart.cart_data.pickup_addresses.push(this.user_helper.user_cart.pickup_address);

    	this.user_helper.add_to_cart((cart_response)=>{
    		this.get_distnce_time()
    	});
    }

    get_distnce_time(){
        let google_distance = 0;
        let google_time = 0;
        let origin = {lat: parseFloat(this.user_helper.user_cart.cart_data.pickup_addresses[0].location[0]), lng: parseFloat(this.user_helper.user_cart.cart_data.pickup_addresses[0].location[1])};
        let destination = {lat: parseFloat(this.address_detail.delivery_location1[0]), lng: parseFloat(this.address_detail.delivery_location1[1])};

        let service = new google.maps.DistanceMatrixService;
        service.getDistanceMatrix({
            origins: [origin],
            destinations: [destination],
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
            travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
            if(status == google.maps.DistanceMatrixStatus.OK){
                this.google_distance = response.rows[0].elements[0].distance != undefined ? response.rows[0].elements[0].distance.value: 0;
                this.google_time = response.rows[0].elements[0].duration != undefined ? response.rows[0].elements[0].duration.value: 0;
                this.get_order_invoice(this.google_distance, this.google_time)
            } else {
                this.get_order_invoice(this.google_distance, this.google_time)
            }
        });
    }

    get_order_invoice(google_distance, google_time){
        this.user_helper.myLoading = true;
        let totalItemsCount = 0;
        this.google_distance = google_distance;
        this.google_time = google_time;

        let get_order_cart_invoice_json = {
            user_id: this.user_id,
            server_token: this.server_token,
            total_distance: google_distance,
            total_time: google_time,
            store_id: null,
            order_type: 7,
            total_cart_price: 0,
            total_item_count: 0,
            is_user_pick_up_order: false,
            cart_unique_token: this.cart_unique_token,
            city_id: this.user_helper.router_id.user.city_id,
            country_id: this.user_helper.router_id.user.country_id,
			      vehicle_id: this.selected_vehicle_id
        }

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_COURIER_ORDER_INVOICE, get_order_cart_invoice_json, (res_data) => {
            this.user_helper.myLoading = false;
            if(res_data.success){
            	this.user_helper.user_cart.server_date = res_data.server_time;
                  this.user_helper.user_cart.timezone = res_data.timezone;
                  this.order_payment = res_data.order_payment;
                  this.user_helper.user_cart.order_payment_id = res_data.order_payment._id;
                	this.step = 2;
                	$('#close_item_model1').click();
            } else {
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message()
            }
            this.get_payment_gateway(this.user_helper.router_id.user_current_location);
        });
    }

    open_payment_dailouge(content){
    	this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title' , windowClass:'product_pop with_img'}).result.then((result) => {
            
        }, (reason) => {

        });
    }

    change_user_wallet_status(event){
        this.user_helper.user_cart.myLoading = true;
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CHANGE_USER_WALLET_STATUS, {user_id: this.user_id, server_token: this.server_token, is_use_wallet: event}, (res_data) => {
            this.user_helper.user_cart.myLoading = false;
            if(res_data.success){
                this.is_use_wallet = event;
            } else {
                this.is_use_wallet = !event;
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
            }
        });
    }

    get_payment_gateway(current_location){

        current_location.user_id = this.user_id;
        current_location.city_id = this.city_id;
        current_location.server_token =  this.server_token;

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_PAYMENT_GATEWAY, current_location, (res_data) => {            if(res_data.success) {
              this.wallet = res_data.wallet;
              this.wallet_currency_code = res_data.wallet_currency_code;
                this.user_helper.myLoading = false;
                this.is_cash_payment_mode = res_data.is_cash_payment_mode;
                this.payment_gateway = res_data.payment_gateway;

                if (this.is_cash_payment_mode) {
                    this.selected_payment_gateway = 'cash';
                }

                if (!this.is_cash_payment_mode && this.payment_gateway.length > 0) {
                    this.selected_payment_gateway = this.payment_gateway[0]._id;
                }
                if (this.payment_gateway.length > 0) {
                    let index = this.payment_gateway.findIndex((x) => (x._id).toString() == this.user_helper.PAYMENT_GATEWAY_CONSTANT.STRIPE)

                    if (index !== -1) {
                        this.get_card()
                    }
                }
            } else {

            }
        });
    }

    get_card() {
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_CARD_LIST, {user_id: this.user_id, server_token: this.server_token}, (res_data) => {
          
            if(res_data.success){
                this.card_list = res_data.cards;
            } else {

            }
        });
    }

    select_card(card_id , card_index){
        if(!this.card_list[card_index].is_default){
            this.user_helper.myLoading = true;
            this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.SELECT_CARD, {user_id: this.user_id, server_token: this.server_token, card_id: card_id}, (res_data) => {
                 this.user_helper.myLoading = false;
                if(res_data.success){
                    let index = this.card_list.findIndex((x)=> x.is_default == true)
                    this.card_list[index].is_default = false;
                    this.card_list[card_index].is_default = true;
                } else {
                    this.user_helper.data.storage = {
                        "message": this.user_helper.ERROR_CODE[res_data.error_code],
                        "class": "alert-danger"
                    }
                }
            });
        }
    }

    create_order(){
        this.user_helper.user_cart.delivery_note = this.address_detail.delivery_note;
        this.user_helper.user_cart.delivery_user_name = this.address_detail.delivery_user_name;
        this.user_helper.user_cart.delivery_user_phone = this.address_detail.delivery_user_phone;
        this.user_helper.user_cart.is_user_pick_up_order = false;
        if(this.selected_payment_gateway == this.user_helper.PAYMENT_GATEWAY_CONSTANT.STRIPE) {
            let index = this.card_list.findIndex((x) => x.is_default == true)
            if (index !== -1) {
                this.pay_order_payment(false);
                $('.close_payment_modal').click();
            } else {

            }
        } else if(this.selected_payment_gateway == this.user_helper.PAYMENT_GATEWAY_CONSTANT.CASH){
            this.pay_order_payment(true);
            $('.close_payment_modal').click();
        }
    }

    pay_order_payment(payment_mode_cash){
        this.user_helper.myLoading = true;
        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            order_payment_id: this.user_helper.user_cart.order_payment_id,
            payment_id: this.selected_payment_gateway,
            is_payment_mode_cash: payment_mode_cash,
            delivery_type: this.user_helper.DELIVERY_TYPE.COURIER,
            store_delivery_id: this.user_helper.router_id.user.delivery_type_id
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.PAY_ORDER_PAYMENT, json, (res_data) => {
       
            if(res_data.success){
                this.create_order_service();
            } else {
                this.user_helper.myLoading = false;
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message();
            }
        });
    }

    create_order_service() {
        let selected_date = this.user_helper.user_cart.schedule_date;
        let milisecond = 0;
        
        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            cart_id: this.user_helper.user_cart.cart_data.cart_id,
            delivery_note: this.user_helper.user_cart.delivery_note,
            delivery_user_name: this.user_helper.user_cart.delivery_user_name,
            delivery_user_phone: this.user_helper.user_cart.delivery_user_phone,
            is_user_pick_up_order: this.user_helper.user_cart.is_user_pick_up_order,
            is_schedule_order: this.user_helper.user_cart.is_schedule_order,
            order_start_at: milisecond,
            delivery_type: this.user_helper.DELIVERY_TYPE.COURIER
        }
        this.form_data.append('user_id', this.user_id);
        this.form_data.append('server_token', this.server_token);
        this.form_data.append('cart_id', this.user_helper.user_cart.cart_data.cart_id);
        this.form_data.append('delivery_note', this.user_helper.user_cart.delivery_note);
        this.form_data.append('delivery_user_name', this.user_helper.user_cart.delivery_user_name);
        this.form_data.append('delivery_user_phone', this.user_helper.user_cart.delivery_user_phone);
        this.form_data.append('is_user_pick_up_order', this.user_helper.user_cart.is_user_pick_up_order.toString());
        this.form_data.append('is_schedule_order', this.user_helper.user_cart.is_schedule_order.toString());
        this.form_data.append('delivery_type', this.user_helper.DELIVERY_TYPE.COURIER.toString());

        this.new_image_array.forEach((image, index)=>{
          this.form_data.append('image'+index, image.file);
        })

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CREATE_ORDER, this.form_data, (res_data) => {
            this.user_helper.myLoading = false;
            this.form_data = new FormData();
            if(res_data.success){
                this.user_helper.router_id.user.order_id = res_data.order_id
                this.user_helper.user_cart.cart_data = {
                    cart_id: null,
                    city_id: null,
                    pickup_addresses: [],
                    destination_addresses: [],
                    cart: [],
                    selectedStoreId: null,
                    total_item: 0
                }
                this.user_helper.user_cart.total_cart_amount = 0;
                this.user_helper.user_cart.order_payment_id = null;

                this.user_helper.data.storage = {
                    "message": this.user_helper.MESSAGE_CODE[res_data.message],
                    "class": "alert-info"
                }
                let uuid = UUID.UUID();
                localStorage.setItem('cart_unique_token', uuid);
                this.user_helper.router.navigate(['thankyou']);
            } else {
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message();
            }
        });
    }

}
